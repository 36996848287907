<template>
  <main>
    <div class="content_wrapper">
      <div class="home-img">
        <div class="home_wrapper">
              <div class="mobile bonnes">
              <img  src="/bonne.png">
            </div>
          <div class="home_left">
            <h2>DU 19 MAI AU 30 SEPTEMBRE 2021</h2>
            <p>
              1 système GROHE Blue acheté dans<br />
              une enseigne participante (cuisiniste et showroom)
            </p>
            <br />
            <img class="desktop" src="../assets/images/home-left.png" />
            <img class="mobile" src="../assets/images/home-left-mobile.png" />

            <router-link tag="button" class="btn-mob mobile" to="/form">Je participe</router-link>
          </div>
          <div class="home_right">
            <div class="desktop bonnes">
              <img  src="/bonne.png">
            </div>
            <img class="desktop" src="../assets/images/home-right.png" /><br /><br />
             <div class="home-infos">
              <img class="caddie" src="../assets/images/caddie.png" />

              <span>Achetez (ou commandez via votre installateur) entre le 19/05/2021
              et le 30/09/2021 <br />
              un ou des systèmes de filtration GROHE Blue dans une enseigne
              participant à l’opération.</span>
            </div>

            <div class="home-infos">
             <!--  <img class="pencil" src="../assets/images/pencil.png" />
              <span>Et faites installer, votre système GROHE Blue par un installateur
              professionnel.</span> -->
            </div>

            <div class="home-comment">
              <h3>1/ INSCRIVEZ-VOUS</h3>
              <p>
                <img src="../assets/images/return.png" />
                <b>Remplissez le formulaire de participation en ligne</b> au
                plus tard le 31/10/2021 en cliquant sur « participer »
                ci-dessous: <br />Vos coordonnées complètes (nom, prénom,
                adresse, CP, ville, email et téléphone), votre IBAN/BIC présent
                sur votre RIB.
              </p>

              <h3>2/ TÉLÉCHARGEZ VOS PREUVES D'ACHAT</h3>
              <p>
                <img class="r2" src="../assets/images/return.png" />
                <b>Joindre la facture d'achat avec la date de pose prévue, le montant de l'acompte</b> que vous
                aurez scannée ou photographiée<br />
                avec les éléments suivants apparents et entourés : date d’achat
                et de pose prévue, le montant de l'acompte, référence,<br />
                libellé et prix du ou des systèmes GROHE Blue. <br /><br />
                Pour les achats faits dans le réseau cuisiniste, envoyez la
                facture d’achat de votre cuisine avec la référence <br />du
                GROHE Blue et l’indication de la date de pose prévue et du montant de
                l’acompte versé.
              </p>
              <h3>3/ VALIDEZ VOTRE PARTICIPATION</h3>
              <p>
                Finalisez votre inscription en ligne en cliquant sur « je
                participe » une fois l’ensemble des éléments demandés complétés.
                <br /><br />
                <img  class="right-mobile mobile" src="../assets/images/home-right-mobile.png">
                <router-link tag="button" class="desktop" to="/form">Participer</router-link>
                <small
                  >Remboursement sous 4 à 6 semaines suivant l’email de
                  confirmation de conformité de votre dossier.</small
                >
                <br /><br />
                <small>
                  GROHE vous offre la possibilité de participer par courrier à
                  cette offre. (Si vous ne pouvez pas participer en ligne)<br />
                  Pour cela, téléchargez et imprimez le coupon de participation
                  en <a href="https://oxoformdocs.fra1.digitaloceanspaces.com/604bba5ec9ff400015f11f9f/modalites.pdf" target="_blank">cliquant ici</a>.</small>

               
              </p>
            </div>
               <img class="part desktop" src="../assets/images/part.png">
          </div>

        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "home",
};
</script>
