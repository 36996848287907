<template>
<div>
    <div>
       <img class="mobile-button mobile" @click="toggle" src="../assets/images/menu-mobile.png">  

        <Drawer @close="toggle" class="menu-mobile" align="right" :closeable="true">

            <div v-if="open">
                <ul>
                    <li @click="toggle" class="test"><router-link  to="/"><img src="../assets/images/logo.jpg"></router-link></li>
                    <li @click="toggle" v-if="currentRouteName != 'wait' && currentRouteName != 'finish'"><router-link to="/">Accueil</router-link ></li>
                    <li @click="toggle" v-if="currentRouteName != 'wait' && currentRouteName != 'finish'"><router-link to="/form">Formulaire d'inscription</router-link></li>             
                    <li @click="toggle" v-if="currentRouteName != 'wait'"><router-link  to="/ref">Références éligibles</router-link></li>
                    <li @click="toggle" v-if="currentRouteName != 'wait'"><a :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/604bba5ec9ff400015f11f9f/modalites.pdf`" target="_blank">Modalités</a></li>
                    <li @click="toggle" v-if="currentRouteName != 'wait' && currentRouteName != 'finish'"><a :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/604bba5ec9ff400015f11f9f/mentions.pdf`" target="_blank">Mentions légales</a></li>
                    <li @click="toggle" v-if="currentRouteName != 'wait' && currentRouteName != 'finish'"><a :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/604bba5ec9ff400015f11f9f/politique.pdf`" target="_blank">Données personelles</a></li>
                    <li @click="toggle" v-if="currentRouteName != 'wait'"><router-link to="/contact">Contact</router-link></li>   
                </ul>
            </div>
         </Drawer>    
    <header>
 
         <div>
         <ul class="nav">
             <li class="test"><router-link  to="/"><img src="../assets/images/logo.jpg"></router-link></li>
             <li v-if="currentRouteName != 'wait' && currentRouteName != 'finish'"><router-link to="/">Accueil</router-link ></li>
             <li v-if="currentRouteName != 'wait' && currentRouteName != 'finish'"><router-link to="/form">Formulaire d'inscription</router-link></li>             
             <li v-if="currentRouteName != 'wait'"><router-link  to="/ref">Références éligibles</router-link></li>
             <li v-if="currentRouteName != 'wait'"><a :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/604bba5ec9ff400015f11f9f/modalites.pdf`" target="_blank">Modalités</a></li>
             <li v-if="currentRouteName != 'wait' && currentRouteName != 'finish'"><a :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/604bba5ec9ff400015f11f9f/mentions.pdf`" target="_blank">Mentions légales</a></li>
             <li v-if="currentRouteName != 'wait' && currentRouteName != 'finish'"><a :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/604bba5ec9ff400015f11f9f/politique.pdf`" target="_blank">Données personelles</a></li>
             <li v-if="currentRouteName != 'wait'"><router-link to="/contact">Contact</router-link></li>                                       
         </ul>
         </div>       
    </header>


  </div>
  </div>  
</template>
<script>
var consts = require('../config');
import Drawer from "vue-simple-drawer"

export default {
    name: 'HeaderElem',
    data() {
        return {
            game_id : consts.GAME_ID,
            open: false
        }
    },
    computed: {
      currentRouteName() {
          return this.$route.name;
      }
  },   
    components: {
        Drawer
    },
    methods: {   
        openParticipate : function() {
              this.$swal({
                confirmButtonText: "OK",
                confirmButtonColor: "#be9f6a",
                html: "<div class=\"popup-participer\">Du 01/04/2021 au 31/12/2021 <br /><br />Tentez de gagner une carte cadeau TRUFFAUT !<br /><br />Pour participer, suivez les instructions ci-dessous :<br /><br /><ul><li> Achetez une bouteille FLEURS DE PRAIRIE porteuse de la collerette promotionnelle</li><li>Munissez-vous de votre ticket de caisse prouvant l'achat</li><li>Remplissez le formulaire de participation et téléchargez votre preuve d'achat</li><li>Une fois l'ensemble des éléments complétés cliquez sur 'PARTICIPER'. Vous découvrirez immédiatement si vous avez gagné !</li></ul></div>",
            })
        },           
        toggle() {
            this.open = !this.open
            console.log(this.open)
        }
    }    
}
</script>