<template>
  <div id="app" v-bind:class="{ tobottom : currentRouteName != 'home',  refbg : currentRouteName == 'ref' }" >
    <headerElem></HeaderElem>
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    <FooterElem></FooterElem>
 </div> 
</template>

<script>
import HeaderElem from '@/components/Header'
import FooterElem from '@/components/Footer'

export default {
  name: 'App',
  data: function () {
    return {
      menu: false
    }
  }, 
  computed: {
      currentRouteName() {
          return this.$route.name;
      }
  }, 
  components: {
    HeaderElem,
    FooterElem,
  }
}
</script>